<template>
  <div id="favorites">
    <section id="contentHeader">
      <h1>Favorites</h1>
    </section>
    <section id="mainContent" class="w-12 bg-white"></section>
    <section id="contentFooter" class="columns"></section>
    <modal></modal>
  </div>
</template>

<script>
// import Vue from "Vue";
// import modal from "../components/modal.vue";

export default {
  components: {},
  data() {
    return {
      loggedIn: false,
      favoritesList:[],
      affirmation:[],
        song:[],
        reading:[],
    };
  },
  mounted() {
    this.$store.watch(
      state => {
        return state.user.authenticated;
      },
      ((newVal, oldVal) => {
        if (newVal !== oldVal) {
          //   this.$forceUpdate();
        }
      }).bind(this),
      {}
    );
  },
  watch: {},
  methods: {}
};
</script>
<style lang="scss">

</style>
